import request from '../util/request'
import qs from 'qs'

export default {
  init(orderId, sign) {
    return request({
      url: '/etax/auth/' + orderId + '/' + sign,
      method: 'get'
    })
  },
  sendValidationCode(data) {
    return request({
      url: '/etax/auth/sendValidationCode',
      method: 'post',
      data
    })
  },
  login(data) {
    return request({
      url: '/etax/auth/login',
      method: 'post',
      data
    })
  },
  agreement(id) {
    return request({
      url: '/etax/auth/agreement/' + id,
      method: 'get'
    })
  },
  hmdInit(orderId) {
    return request({
      url: '/apply/hmd/init?orderId=' + orderId,
      method: 'get'
    })
  },
  hmdSendSms(creditCode) {
    return request({
      url: '/apply/hmd/sendSms',
      method: 'post',
      data: qs.stringify({creditCode})
    })
  },
  hmdAuth(data) {
    return request({
      url: '/apply/hmd/auth',
      method: 'post',
      data
    })
  },
  checkAuthLink(orderId, sign) {
    return request({
      url: '/etax/auth/simple/' + orderId + '/' + sign,
      method: 'get'
    })
  },
  checkAuthLinkStep1(nsrsbh, nsrmc) {
    return request({
      url: '/etax/auth/simple/' + nsrsbh + '/' + nsrmc + "/step1",
      method: 'get'
    })
  },
  generateAuthLink(nsrsbh, nsrmc) {
    return request({
      url: '/etax/auth/generate/auth-link/' + nsrsbh + '/' + nsrmc,
      method: 'get'
    })
  },
  sendSMS(data) {
    return request({
      url: '/etax/auth/send/sms',
      method: 'post',
      data
    })
  },
  doLogin(data) {
    return request({
      url: '/etax/auth/login/do',
      method: 'post',
      data
    })
  },
}